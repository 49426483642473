import i18n from 'i18next';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const locizeOptions = {
  projectId: '859053ec-2edf-4a26-b632-d6b1e4f067b4',
  referenceLng: 'en',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    ns: ['common'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
  });

export default i18n;
